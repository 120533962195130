import Apartment from "./assets/apartment.svg";
import ApartmentWhite from "./assets/apartment-white.svg";
import AppStore from "./assets/appStore.svg";
import Architecture from "./assets/architecture.svg";
import ArchitectureWhite from "./assets/architecture-white.svg";
import Bench from "./assets/bench.svg";
import BenchWhite from "./assets/bench-white.svg";
import Biking from "./assets/biking.svg";
import BnB from "./assets/bnb.svg";
import BnBWhite from "./assets/bnb-white.svg";
import Cafe from "./assets/cafe.svg";
import CafeWhite from "./assets/caffe-white.svg";
import Camp from "./assets/camp.svg";
import CampWhite from "./assets/camp-white.svg";
import Camper from "./assets/camper.svg";
import CamperWhite from "./assets/camper-white.svg";
import Castle from "./assets/castle.svg";
import CastleWhite from "./assets/castle-white.svg";
import ChevronDown from "./assets/chevron-down.svg";
import Church from "./assets/church.svg";
import ChurchWhite from "./assets/church-white.svg";
import Download from "./assets/download.svg";
import Electricity from "./assets/electricity.svg";
import Facebook from "./assets/facebook.svg";
import Farm from "./assets/farm.svg";
import FarmWhite from "./assets/farm-white.svg";
import Fire from "./assets/fire.svg";
import FireWhite from "./assets/fire-white.svg";
import GooglePlay from "./assets/googlePlay.svg";
import Hamburger from "./assets/hamburger.svg";
import HeroLogo from "./assets/hero-logo.svg";
import Hiking from "./assets/hiking.svg";
import Hostel from "./assets/hostel.svg";
import HostelWhite from "./assets/hostel-white.svg";
import Hotel from "./assets/hotel.svg";
import Inn from "./assets/inn.svg";
import InnWhite from "./assets/inn-white.svg";
import Kort from "./assets/kort.svg";
import LeftArrow from "./assets/left-arrow.svg";
import ArrowDownLG from "./assets/lg/arrowDown.svg";
import ArrowLeftLG from "./assets/lg/arrowLeft.svg";
import ArrowRightLG from "./assets/lg/arrowRight.svg";
import ArrowUpLG from "./assets/lg/arrowUp.svg";
import BadLG from "./assets/lg/bad.svg";
import BaenkLG from "./assets/lg/baenk.svg";
import BalpladsLG from "./assets/lg/balplads.svg";
import BnbLG from "./assets/lg/bnb.svg";
import BondegardsferierLG from "./assets/lg/bondegardsferier.svg";
import BusLG from "./assets/lg/bus.svg";
import CafeLG from "./assets/lg/cafe.svg";
import CampingpladserLG from "./assets/lg/campingpladser.svg";
import CheckmarkLG from "./assets/lg/checkmark.svg";
import ChevronDownLG from "./assets/lg/chevronDown.svg";
import ChevronleftLG from "./assets/lg/chevronLeft.svg";
import ChevronRightLG from "./assets/lg/chevronRight.svg";
import ChevronUpLG from "./assets/lg/chevronUp.svg";
import CloseLG from "./assets/lg/close.svg";
import CompassLG from "./assets/lg/compass.svg";
import Compass1LG from "./assets/lg/compass1.svg";
import Compass3LG from "./assets/lg/compass3.svg";
import CykelLG from "./assets/lg/cykel.svg";
import CykelpumpestationerLG from "./assets/lg/cykelpumpestationer.svg";
import DownloadLG from "./assets/lg/download.svg";
import ElementLG from "./assets/lg/element.svg";
import FavoriteLG from "./assets/lg/favorite.svg";
import FlstationerTilLadcyklerLG from "./assets/lg/felstationerTilLadcykler.svg";
import FerielejlighederLG from "./assets/lg/ferielejligheder.svg";
import FilterLG from "./assets/lg/filter.svg";
import Flag4LG from "./assets/lg/flag4.svg";
import Flag5LG from "./assets/lg/flag5.svg";
import FortidsminderOgRuinerLG from "./assets/lg/fortidsminderOgRuiner.svg";
import GpsLG from "./assets/lg/gps.svg";
import HotellerLG from "./assets/lg/hoteller.svg";
import IndkobLG from "./assets/lg/indkob.svg";
import KirkerLG from "./assets/lg/kirker.svg";
import KnudepunktLG from "./assets/lg/knudepunkt.svg";
import KortLG from "./assets/lg/kort.svg";
import KroerLG from "./assets/lg/kroer.svg";
import LejrskolerLG from "./assets/lg/lejrskoler.svg";
import LikeLG from "./assets/lg/like.svg";
import LineLG from "./assets/lg/line.svg";
import LokaleSpiseoplevelserLG from "./assets/lg/lokaleSpiseoplevelser.svg";
import LuggageLG from "./assets/lg/luggage.svg";
import MadpakkehusLG from "./assets/lg/madpakkehus.svg";
import MapLG from "./assets/lg/map.svg";
import Map2LG from "./assets/lg/map2.svg";
import MarkerPin1LG from "./assets/lg/markerPin1.svg";
import MarkerPin5LG from "./assets/lg/markerPin5.svg";
import MarkerPinLostLG from "./assets/lg/markerPinLost.svg";
import MinusLG from "./assets/lg/minus.svg";
import MuseerLG from "./assets/lg/museer.svg";
import NavigationLG from "./assets/lg/navigation.svg";
import NavigationPointer1LG from "./assets/lg/navigationPointer1.svg";
import NavigationPointer2LG from "./assets/lg/navigationPointer2.svg";
import NoResultsLG from "./assets/lg/noResults.svg";
import ParkeringLG from "./assets/lg/parkering.svg";
import PharmacyLG from "./assets/lg/pharmacy.svg";
import PlaceholderLG from "./assets/lg/placeholder.svg";
import PlusLG from "./assets/lg/plus.svg";
import RestauranterLG from "./assets/lg/restauranter.svg";
import RouteLG from "./assets/lg/route.svg";
import RoutingLG from "./assets/lg/routing.svg";
import SettingLG from "./assets/lg/setting.svg";
import ShelterLG from "./assets/lg/shelter.svg";
import SlotteOgHerregardeLG from "./assets/lg/slotteOgHerregarde.svg";
import StampLG from "./assets/lg/stamp.svg";
import TeltLG from "./assets/lg/telt.svg";
import ToiletterLG from "./assets/lg/toiletter.svg";
import TrainLG from "./assets/lg/train.svg";
import UploadLG from "./assets/lg/upload.svg";
import VandposterLG from "./assets/lg/vandposter.svg";
import VandringLG from "./assets/lg/vandring.svg";
import WarningLG from "./assets/lg/warning.svg";
import ZoomInLG from "./assets/lg/zoomIn.svg";
import ZoomOutLG from "./assets/lg/zoomOut.svg";
import LinkedIn from "./assets/linkedIn.svg";
import Local from "./assets/local.svg";
import LocalWhite from "./assets/locale-white.svg";
import Logo from "./assets/logo.svg";
import LogoMarker from "./assets/logoMarker.svg";
import Madpakkehus from "./assets/madpakkehus.svg";
import MadpakkehusWhite from "./assets/madpakkehus-white.svg";
import Map from "./assets/map.svg";
import MarkerFallback from "./assets/marker-fallback.svg";
import MarkerFallbackWhite from "./assets/marker-fallback-white.svg";
import Museum from "./assets/museum.svg";
import MuseumWhite from "./assets/museum-white.svg";
import NavLogo from "./assets/nav-logo.svg";
import NordeaLogo from "./assets/nordea-logo.svg";
import Park from "./assets/park.svg";
import ParkWhite from "./assets/park-white.svg";
import Parking from "./assets/parking.svg";
import ParkingWhite from "./assets/parking-white.svg";
import Pharmacy from "./assets/pharmacy.svg";
import PharmacyWhite from "./assets/pharmacy-white.svg";
import ArrowDownPrimary from "./assets/primary/arrowDown.svg";
import ArrowLeftPrimary from "./assets/primary/arrowLeft.svg";
import ArrowRightPrimary from "./assets/primary/arrowRight.svg";
import ArrowUpPrimary from "./assets/primary/arrowUp.svg";
import BadPrimary from "./assets/primary/bad.svg";
import BaenkPrimary from "./assets/primary/baenk.svg";
import BalpladsPrimary from "./assets/primary/balplads.svg";
import BnbPrimary from "./assets/primary/bnb.svg";
import BondegardsferierPrimary from "./assets/primary/bondegardsferier.svg";
import BusPrimary from "./assets/primary/bus.svg";
import CafePrimary from "./assets/primary/cafe.svg";
import CampingpladserPrimary from "./assets/primary/campingpladser.svg";
import CheckmarkPrimary from "./assets/primary/checkmark.svg";
import ChevronDownPrimary from "./assets/primary/chevronDown.svg";
import ChevronleftPrimary from "./assets/primary/chevronLeft.svg";
import ChevronRightPrimary from "./assets/primary/chevronRight.svg";
import ChevronUpPrimary from "./assets/primary/chevronUp.svg";
import ClosePrimary from "./assets/primary/close.svg";
import CompassPrimary from "./assets/primary/compass.svg";
import Compass1Primary from "./assets/primary/compass1.svg";
import Compass3Primary from "./assets/primary/compass3.svg";
import CykelPrimary from "./assets/primary/cykel.svg";
import CykelpumpestationerPrimary from "./assets/primary/cykelpumpestationer.svg";
import DownloadPrimary from "./assets/primary/download.svg";
import ElementPrimary from "./assets/primary/element.svg";
import FavoritePrimary from "./assets/primary/favorite.svg";
import FlstationerTilLadcyklerPrimary from "./assets/primary/felstationerTilLadcykler.svg";
import FerielejlighederPrimary from "./assets/primary/ferielejligheder.svg";
import FilterPrimary from "./assets/primary/filter.svg";
import Flag4Primary from "./assets/primary/flag4.svg";
import Flag5Primary from "./assets/primary/flag5.svg";
import FortidsminderOgRuinerPrimary from "./assets/primary/fortidsminderOgRuiner.svg";
import GpsPrimary from "./assets/primary/gps.svg";
import HotellerPrimary from "./assets/primary/hoteller.svg";
import IndkobPrimary from "./assets/primary/indkob.svg";
import KirkerPrimary from "./assets/primary/kirker.svg";
import KnudepunktPrimary from "./assets/primary/knudepunkt.svg";
import KortPrimary from "./assets/primary/kort.svg";
import KroerPrimary from "./assets/primary/kroer.svg";
import LejrskolerPrimary from "./assets/primary/lejrskoler.svg";
import LikePrimary from "./assets/primary/like.svg";
import LinePrimary from "./assets/primary/line.svg";
import LokaleSpiseoplevelserPrimary from "./assets/primary/lokaleSpiseoplevelser.svg";
import LuggagePrimary from "./assets/primary/luggage.svg";
import MadpakkehusPrimary from "./assets/primary/madpakkehus.svg";
import MapPrimary from "./assets/primary/map.svg";
import Map2Primary from "./assets/primary/map2.svg";
import MarkerPin1Primary from "./assets/primary/markerPin1.svg";
import MarkerPin5Primary from "./assets/primary/markerPin5.svg";
import MarkerPinLostPrimary from "./assets/primary/markerPinLost.svg";
import MinusPrimary from "./assets/primary/minus.svg";
import MuseerPrimary from "./assets/primary/museer.svg";
import NavigationPrimary from "./assets/primary/navigation.svg";
import NavigationPointer1Primary from "./assets/primary/navigationPointer1.svg";
import NavigationPointer2Primary from "./assets/primary/navigationPointer2.svg";
import NoResultsPrimary from "./assets/primary/noResults.svg";
import ParkeringPrimary from "./assets/primary/parkering.svg";
import PharmacyPrimary from "./assets/primary/pharmacy.svg";
import PlaceholderPrimary from "./assets/primary/placeholder.svg";
import PlusPrimary from "./assets/primary/plus.svg";
import RestauranterPrimary from "./assets/primary/restauranter.svg";
import RoutePrimary from "./assets/primary/route.svg";
import RoutingPrimary from "./assets/primary/routing.svg";
import SettingPrimary from "./assets/primary/setting.svg";
import ShelterPrimary from "./assets/primary/shelter.svg";
import SlotteOgHerregardePrimary from "./assets/primary/slotteOgHerregarde.svg";
import StampPrimary from "./assets/primary/stamp.svg";
import TeltPrimary from "./assets/primary/telt.svg";
import ToiletterPrimary from "./assets/primary/toiletter.svg";
import TrainPrimary from "./assets/primary/train.svg";
import UploadPrimary from "./assets/primary/upload.svg";
import VandposterPrimary from "./assets/primary/vandposter.svg";
import VandringPrimary from "./assets/primary/vandring.svg";
import WarningPrimary from "./assets/primary/warning.svg";
import ZoomInPrimary from "./assets/primary/zoomIn.svg";
import ZoomOutPrimary from "./assets/primary/zoomOut.svg";
import Pump from "./assets/pump.svg";
import PumpWhite from "./assets/pump-white.svg";
import Restaurant from "./assets/restaurant.svg";
import RestaurantWhite from "./assets/restaurant-white.svg";
import Restroom from "./assets/restroom.svg";
import RestroomWhite from "./assets/restroom-white.svg";
import RightArrow from "./assets/right-arrow.svg";
import Ruin from "./assets/ruin.svg";
import RuinWhite from "./assets/ruin-white.svg";
import Sculpture from "./assets/sculpture.svg";
import SculptureWhite from "./assets/sculpture-white.svg";
import Search from "./assets/search.svg";
import Shelter from "./assets/shelter.svg";
import ShelterWhite from "./assets/shelter-white.svg";
import Shop from "./assets/shop.svg";
import ShopWhite from "./assets/shop-white.svg";
import SmallLogo from "./assets/small-logo.svg";
import SmallLogoWhite from "./assets/small-logo-white.svg";
import SmallAppStore from "./assets/smallAppStore.svg";
import SmallGooglePlay from "./assets/smallGooglePlay.svg";
import Stemp from "./assets/stemp.svg";
import Tent from "./assets/tent.svg";
import TentWhite from "./assets/tent-white.svg";
import Twitter from "./assets/twitter.svg";
import Water from "./assets/water.svg";
import WaterWhite from "./assets/water-white.svg";
import Youtube from "./assets/youtube.svg";

const icons = {
  //logos
  logo: Logo,
  heroLogo: HeroLogo,
  smallLogo: SmallLogo,
  smallLogoWhite: SmallLogoWhite,
  nordeaLogo: NordeaLogo,
  navLogo: NavLogo,
  logoMarker: LogoMarker,
  // tags
  biking: Biking,
  hiking: Hiking,
  map: Map,
  //markers
  stemp: Stemp,
  apartment: Apartment,
  bench: Bench,
  bnb: BnB,
  cafe: Cafe,
  camp: Camp,
  camper: Camper,
  castle: Castle,
  church: Church,
  electricity: Electricity,
  farm: Farm,
  fire: Fire,
  hostel: Hostel,
  hotel: Hotel,
  inn: Inn,
  local: Local,
  madpakkehus: Madpakkehus,
  museum: Museum,
  parking: Parking,
  pump: Pump,
  restaurant: Restaurant,
  restroom: Restroom,
  ruin: Ruin,
  shelter: Shelter,
  shop: Shop,
  tent: Tent,
  water: Water,
  pharmacy: Pharmacy,
  markerFallback: MarkerFallback,
  // other
  search: Search,
  //markers overlay
  restaurantWhite: RestaurantWhite,
  restroomWhite: RestroomWhite,
  cafeWhite: CafeWhite,
  localWhite: LocalWhite,
  tentWhite: TentWhite,
  shelterWhite: ShelterWhite,
  bnbWhite: BnBWhite,
  hostelWhite: HostelWhite,
  apartmentWhite: ApartmentWhite,
  campWhite: CampWhite,
  camperWhite: CamperWhite,
  innWhite: InnWhite,
  farmWhite: FarmWhite,
  markerFallbackWhite: MarkerFallbackWhite,
  waterWhite: WaterWhite,
  benchWhite: BenchWhite,
  madpakkehusWhite: MadpakkehusWhite,
  fireWhite: FireWhite,
  parkingWhite: ParkingWhite,
  churchWhite: ChurchWhite,
  museumWhite: MuseumWhite,
  ruinWhite: RuinWhite,
  castleWhite: CastleWhite,
  shopWhite: ShopWhite,
  pumpWhite: PumpWhite,
  pharmacyWhite: PharmacyWhite,
  architecture: Architecture,
  architectureWhite: ArchitectureWhite,
  sculpture: Sculpture,
  sculptureWhite: SculptureWhite,
  park: Park,
  parkWhite: ParkWhite,
  //socialMedia
  linkedIn: LinkedIn,
  twitter: Twitter,
  youtube: Youtube,
  facebook: Facebook,
  //icons
  hamburger: Hamburger,
  rightArrow: RightArrow,
  leftArrow: LeftArrow,
  download: Download,
  kort: Kort,
  appStore: AppStore,
  googlePlay: GooglePlay,
  smallAppStore: SmallAppStore,
  smallGooglePlay: SmallGooglePlay,
  chevronDown: ChevronDown,
  //icons lg
  arrowdownLG: ArrowDownLG,
  arrowleftLG: ArrowLeftLG,
  arrowrightLG: ArrowRightLG,
  arrowupLG: ArrowUpLG,
  badLG: BadLG,
  baenkLG: BaenkLG,
  balpladsLG: BalpladsLG,
  bnbLG: BnbLG,
  bondegardsferierLG: BondegardsferierLG,
  busLG: BusLG,
  cafeLG: CafeLG,
  campingpladserLG: CampingpladserLG,
  checkmarkLG: CheckmarkLG,
  chevrondownLG: ChevronDownLG,
  chevronleftLG: ChevronleftLG,
  chevronrightLG: ChevronRightLG,
  chevronupLG: ChevronUpLG,
  closeLG: CloseLG,
  compassLG: CompassLG,
  compass1LG: Compass1LG,
  compass3LG: Compass3LG,
  cykelLG: CykelLG,
  cykelpumpestationerLG: CykelpumpestationerLG,
  downloadLG: DownloadLG,
  elementLG: ElementLG,
  favoriteLG: FavoriteLG,
  ferielejlighederLG: FerielejlighederLG,
  filterLG: FilterLG,
  flag4LG: Flag4LG,
  flag5LG: Flag5LG,
  flstationerTilLadcyklerLG: FlstationerTilLadcyklerLG,
  fortidsminderogruinerLG: FortidsminderOgRuinerLG,
  gpsLG: GpsLG,
  hotellerLG: HotellerLG,
  indkobLG: IndkobLG,
  kirkerLG: KirkerLG,
  knudepunktLG: KnudepunktLG,
  kortLG: KortLG,
  kroerLG: KroerLG,
  lejrskolerLG: LejrskolerLG,
  likeLG: LikeLG,
  lineLG: LineLG,
  lokalespiseoplevelserLG: LokaleSpiseoplevelserLG,
  luggageLG: LuggageLG,
  madpakkehusLG: MadpakkehusLG,
  mapLG: MapLG,
  map2LG: Map2LG,
  markerpin1LG: MarkerPin1LG,
  markerpin5LG: MarkerPin5LG,
  markerpinlostLG: MarkerPinLostLG,
  minusLG: MinusLG,
  museerLG: MuseerLG,
  navigationLG: NavigationLG,
  navigationpointer1LG: NavigationPointer1LG,
  navigationpointer2LG: NavigationPointer2LG,
  noresultsLG: NoResultsLG,
  parkeringLG: ParkeringLG,
  pharmacyLG: PharmacyLG,
  placeholderLG: PlaceholderLG,
  plusLG: PlusLG,
  restauranterLG: RestauranterLG,
  routeLG: RouteLG,
  routingLG: RoutingLG,
  settingLG: SettingLG,
  shelterLG: ShelterLG,
  slotteogherregardeLG: SlotteOgHerregardeLG,
  stampLG: StampLG,
  teltLG: TeltLG,
  toiletterLG: ToiletterLG,
  trainLG: TrainLG,
  uploadLG: UploadLG,
  vandposterLG: VandposterLG,
  vandringLG: VandringLG,
  warningLG: WarningLG,
  zoominLG: ZoomInLG,
  zoomoutLG: ZoomOutLG,
  arrowDownPrimary: ArrowDownPrimary,
  arrowleftPrimary: ArrowLeftPrimary,
  arrowlightPrimary: ArrowRightPrimary,
  arrowupPrimary: ArrowUpPrimary,
  badPrimary: BadPrimary,
  baenkPrimary: BaenkPrimary,
  balpladsPrimary: BalpladsPrimary,
  bnbPrimary: BnbPrimary,
  bondegardsferierPrimary: BondegardsferierPrimary,
  busPrimary: BusPrimary,
  cafePrimary: CafePrimary,
  campingpladserPrimary: CampingpladserPrimary,
  checkmarkPrimary: CheckmarkPrimary,
  chevrondownPrimary: ChevronDownPrimary,
  chevronleftPrimary: ChevronleftPrimary,
  chevronrightPrimary: ChevronRightPrimary,
  chevronupPrimary: ChevronUpPrimary,
  closePrimary: ClosePrimary,
  compassPrimary: CompassPrimary,
  compass1Primary: Compass1Primary,
  compass3Primary: Compass3Primary,
  cykelPrimary: CykelPrimary,
  cykelpumpestationerPrimary: CykelpumpestationerPrimary,
  downloadPrimary: DownloadPrimary,
  elementPrimary: ElementPrimary,
  favoritePrimary: FavoritePrimary,
  flstationerTilLadcyklerPrimary: FlstationerTilLadcyklerPrimary,
  ferielejlighederPrimary: FerielejlighederPrimary,
  filterPrimary: FilterPrimary,
  flag4Primary: Flag4Primary,
  flag5Primary: Flag5Primary,
  fortidsminderOgRuinerPrimary: FortidsminderOgRuinerPrimary,
  gpsPrimary: GpsPrimary,
  hotellerPrimary: HotellerPrimary,
  indkobPrimary: IndkobPrimary,
  kirkerPrimary: KirkerPrimary,
  knudepunktPrimary: KnudepunktPrimary,
  kortPrimary: KortPrimary,
  kroerPrimary: KroerPrimary,
  lejrskolerPrimary: LejrskolerPrimary,
  likePrimary: LikePrimary,
  linePrimary: LinePrimary,
  lokaleSpiseoplevelserPrimary: LokaleSpiseoplevelserPrimary,
  luggagePrimary: LuggagePrimary,
  madpakkehusPrimary: MadpakkehusPrimary,
  mapPrimary: MapPrimary,
  map2Primary: Map2Primary,
  markerPin1Primary: MarkerPin1Primary,
  markerPin5Primary: MarkerPin5Primary,
  markerPinLostPrimary: MarkerPinLostPrimary,
  minusPrimary: MinusPrimary,
  museerPrimary: MuseerPrimary,
  navigationPrimary: NavigationPrimary,
  navigationPointer1Primary: NavigationPointer1Primary,
  navigationPointer2Primary: NavigationPointer2Primary,
  noResultsPrimary: NoResultsPrimary,
  parkeringPrimary: ParkeringPrimary,
  pharmacyPrimary: PharmacyPrimary,
  placeholderPrimary: PlaceholderPrimary,
  plusPrimary: PlusPrimary,
  restauranterPrimary: RestauranterPrimary,
  routePrimary: RoutePrimary,
  routingPrimary: RoutingPrimary,
  settingPrimary: SettingPrimary,
  shelterPrimary: ShelterPrimary,
  slotteOgHerregardePrimary: SlotteOgHerregardePrimary,
  stampPrimary: StampPrimary,
  teltPrimary: TeltPrimary,
  toiletterPrimary: ToiletterPrimary,
  trainPrimary: TrainPrimary,
  uploadPrimary: UploadPrimary,
  vandposterPrimary: VandposterPrimary,
  vandringPrimary: VandringPrimary,
  warningPrimary: WarningPrimary,
  zoomInPrimary: ZoomInPrimary,
  zoomOutPrimary: ZoomOutPrimary,
};

export type IconType = keyof typeof icons;

interface IIconProps {
  iconName: IconType;
}

export function Icon({ iconName }: IIconProps) {
  const Icon = icons[iconName];
  return <Icon />;
}
